exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-html-tsx": () => import("./../../../src/pages/contact.html.tsx" /* webpackChunkName: "component---src-pages-contact-html-tsx" */),
  "component---src-pages-datenschutz-html-tsx": () => import("./../../../src/pages/datenschutz.html.tsx" /* webpackChunkName: "component---src-pages-datenschutz-html-tsx" */),
  "component---src-pages-impressum-html-tsx": () => import("./../../../src/pages/impressum.html.tsx" /* webpackChunkName: "component---src-pages-impressum-html-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-publications-html-tsx": () => import("./../../../src/pages/publications.html.tsx" /* webpackChunkName: "component---src-pages-publications-html-tsx" */),
  "component---src-pages-research-in-progress-html-tsx": () => import("./../../../src/pages/research_in_progress.html.tsx" /* webpackChunkName: "component---src-pages-research-in-progress-html-tsx" */),
  "component---src-pages-teaching-html-tsx": () => import("./../../../src/pages/teaching.html.tsx" /* webpackChunkName: "component---src-pages-teaching-html-tsx" */)
}

